<!-- eslint-disable global-require -->
<template>
  <div class="add-employees">
    <div class="card-form">
      <h1 class="title">Add Device to Gulftic</h1>

      <form @submit.prevent="addEmployees">
        <div class="row">
          <div class="col">
            <div style="width: 100%">
              <span
                class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
                >Name</span
              >
              <select
                v-model="selectedOption"
                @change="handleSelectChange"
                class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
              >
                <option
                  v-for="(option, index) in nameOptions"
                  :key="index"
                  :value="option"
                >
                  {{ option }}
                </option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div v-if="showOtherInput" style="width: 100%">
            <label
              class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
            >
              Name</label
            >
            <input
              class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
              v-model="employeesForm.name"
              type="text"
            />
          </div>

          <div class="col">
            <label
              class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
              >Branch Name
            </label>
            <select
              class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
              v-model="employeesForm.branch_id"
            >
              <option
                v-for="branch in branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label
              class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
              >Brand
            </label>
            <input
              class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
              v-model="employeesForm.brand"
              type="text"
              @input="restrictToNumbers"
            />
          </div>

          <div class="col photo-input">
            <div class="ml-8">
              <img id="previewImage" class="img" :src="employeesForm.image" />
            </div>
            <div>
              <button class="upload-btn" type="button" @click="openFileInput">
                Upload Device pic
              </button>
              <input
                ref="fileInput"
                type="file"
                class="input-file"
                style="display: none"
                @change="handleFileSelect"
              />
            </div>
          </div>
        </div>

        <div v-if="error !== ''" class="my-10">
          <h3
            :class="`${
              responseState === 'successfully' ? 'error-success' : 'error'
            }`"
          >
            {{ error }}
          </h3>
        </div>

        <div class="aline-center mt-16">
          <button class="add-btn mb-16" type="submit">save & welcome</button>
        </div>
      </form>
    </div>

    <loadingSpinner v-if="loading" class="loading" />
  </div>
</template>

<script>
import axios from "axios";
import loadingSpinner from "../components/reusable/LoadingSpannier.vue";

export default {
  components: {
    loadingSpinner
  },

  data() {
    return {
      token: "",
      error: "",
      responseState: "",
      loading: false,
      selectedOption: "",
      showOtherInput: false,
      nameOptions: [],
      branches: [],
      employeesForm: {
        name: "",
        branch_id: "",
        brand: "",
        image: ""
      }
    };
  },

  mounted() {
    this.getDevices();
    this.getBranch();
  },
  methods: {
    getBranch() {
      this.loadtable = true;
      axios
        .get("https://gulftic-system.fci.group/api/branch")
        .then((res) => {
          this.branches = res.data.data.map((item) => ({
            id: item.id,
            name: item.name
          }));
        })
        .finally(() => {
          this.loadtable = false;
        });
    },
    restrictToNumbers(event) {
      // Remove non-numeric characters from the input value
      this.employeesForm.mobile = event.target.value.replace(/[^\d+]/g, "");
    },
    handleSelectChange() {
      this.showOtherInput = this.selectedOption === "other";
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.employeesForm.image = file;

      const reader = new FileReader();

      reader.onload = (e) => {
        const previewImage = document.getElementById("previewImage");
        previewImage.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },

    getDevices() {
      axios
        .get(" https://gulftic-system.fci.group/api/devices-list")
        .then((res) => {
          this.nameOptions = res.data.data.map((item) => item.name);
          console.log(this.nameOptions);
        });
    },

    addEmployees() {
      this.loading = true;

      let formData = new FormData();

      formData.append("name", this.employeesForm.name || this.selectedOption);
      formData.append("branch_id", this.employeesForm.branch_id);
      formData.append("brand", this.employeesForm.brand);
      formData.append("image", this.employeesForm.image);

      axios
        .post(` https://gulftic-system.fci.group/api/device`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json, text/plain, */*"
          }
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.loading = false;
            this.responseState = "successfully";
            this.error =
              "Welcome to Gulftic. Please wait for the admin to verify your information. Your details will be sent.";
            //wait 3 seconds before redirecting to the employee page
            this.employeesForm = {
              name: "",
              branch_id: "",
              brand: "",
              image: ""
            };
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
          console.error("Error adding :", error);
        });
    }
  }
};
</script>

<style scoped>
.add-employees {
  position: absolute;
  text-emphasis-color: hsl(226, 80%, 53%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;

  /* background: white; */
}
.card-form {
  background-color: #f7f7f7;
}

.title {
  font-size: 49px;
  margin: 10px;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}
.col {
  width: 50%;
  margin: 8px;
}
.photo-input {
  display: flex;
  align-items: center;
  justify-content: start;
}
.upload-btn {
  background-color: #0564f2;
  color: #f7f7f7;
  margin-left: 10px;
  padding: 8px 28px;
  border-radius: 13px;
}
.upload-btn:hover {
  background-color: #195b99;
}
.add-btn {
  background-color: #103f86;
  color: #f7f7f7;
  margin-left: 10px;
  padding: 13px 38px;
  border-radius: 13px;
}
.add-btn:hover {
  background-color: #0a2853;
}
.input-file {
  position: relative;
  background-color: #0564f2;
}
.img {
  width: 150px;
  height: 100px;
}
.error-success {
  background-color: #388e3c;
  padding: 10px;
  border-radius: 18px;
  width: 50%;
  align-items: center;
  margin: auto;
  color: white;
  font-size: larger;
  box-shadow: 0 0 10px #388e3c;
}
.error {
  background-color: #e53935;
  padding: 10px;
  border-radius: 18px;
  width: 50%;
  align-items: center;
  margin: auto;
  color: white;
  font-size: larger;
  box-shadow: 0 0 10px #e53935;
}
.loading {
  position: fixed;
  top: 0%;
  background-color: antiquewhite;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .photo-input {
    display: flex;
    flex-direction: column;
  }
  .upload-btn {
    margin-top: 10px;
  }
  .row {
    flex-wrap: wrap;
  }
  .col {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
